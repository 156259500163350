
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Profile } from '@/models/profile.model';

@Component({
  components: {},
})
export default class JnProfile extends Vue {
  @Prop({ required: true, default: '' })
  profile!: Profile;
}
