
import { Component, Vue } from "vue-property-decorator";
import { Document } from "@/models/document.model";
import { SearchData } from "@/models/search.model";
import { EsService } from "@/services/es.service";

@Component({
  components: {},
})
export default class JnChiSiamoSecondStrip extends Vue {
  redazioneJuranet = new Document({
    id: "1",
    seq: 1,
    target: "",
    type: "",
    isFragment: false,
    imageSrc: "",
    title: "",
    abstract: "",
    text: "",
    tags: [],
    date: "",
    categories: ["Category"],
    idCategory: "",
    idAreaTematica: "",
    areaTematica: "",
    idMateria: "",
    idArgomento: "",
    materia: "",
    argomento: "",
    author: "",
    descrAuthor: "",
    size: 0,
    highlights: [],
    index: "",
    access: 1,
    since: "",
    to: "",
    sezioneCassazione: "",
    content: "",
    abrogato: false,
    note: []
  });
  fondazioneAiga = this.redazioneJuranet;
  studioCeroli = this.redazioneJuranet;
  studioPirola = this.redazioneJuranet;
  sprint = this.redazioneJuranet;
  calogeroCommandatore = this.redazioneJuranet;
  lucaPavia = this.redazioneJuranet;
  mariangelaDiBiase = this.redazioneJuranet;
  elisaBrino = this.redazioneJuranet;

  async beforeCreate() {
    const searchData = new SearchData({
      index: process.env.VUE_APP_JURANEWS_INDEXES!,
      size: 10,
      from: 0,
    });
    this.redazioneJuranet = await EsService.getChiSiamoThirdStrip(1);
    this.fondazioneAiga = await EsService.getChiSiamoThirdStrip(2);
    this.studioCeroli = await EsService.getChiSiamoThirdStrip(3);
    this.studioPirola = await EsService.getChiSiamoThirdStrip(4);
    this.sprint = await EsService.getChiSiamoThirdStrip(5);
    this.calogeroCommandatore = await EsService.getChiSiamoThirdStrip(6);
    this.lucaPavia = await EsService.getChiSiamoThirdStrip(7);
    this.mariangelaDiBiase = await EsService.getChiSiamoThirdStrip(8);
    this.elisaBrino = await EsService.getChiSiamoThirdStrip(9);
  }
}
